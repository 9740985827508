/* eslint-disable camelcase */

import { GoogleAdsConversionAccountId } from '../../lib/googleAds';

const isProd = process.env.NODE_ENV === 'production';

const SyncWithId = isProd ? 'G-W9FB11X4XK' : 'G-1TQ6R1ZGBR';

export const Ga4Id = SyncWithId;

export type PurchaseItem = {
  item_id: string;
  item_name: string;
  price: number;
  quantity: number;
};

// https://developers.google.com/analytics/devguides/collection/ga4/set-up-ecommerce
export type PurchaseEvent = {
  action: 'purchase';
  transaction_id: string;
  value: number;
  currency: string;
  items?: PurchaseItem[];
};

type EventArgs =
  | PurchaseEvent
  | {
      action: string;
      event_category?: string;
      event_label?: string;
    };

export const event = (args: EventArgs) => {
  const { action, ...otherArgs } = args;
  return new Promise<void>((resolve) => {
    window.gtag('event', action, {
      send_to: Ga4Id,
      ...otherArgs,
      event_callback: () => {
        resolve();
      },
    });
  });
};

export const GoogleAnalytics4 = () => {
  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${Ga4Id}`}
      />
      <script
        async
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              // Configure GA4 tracking
              gtag('config', '${Ga4Id}');
              // Configure google ads
              gtag('config', '${GoogleAdsConversionAccountId}');
          `,
        }}
      />
    </>
  );
};
